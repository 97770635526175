// ** React Imports
import React from "react";

// ** Hook Imports
import SeoBanner from "../components/lp/SeoBanner";
import Hero from "../components/lp/Hero";
import Partners from "../components/lp/Partners";
import Team from "../components/lp/Team";
import Report from "../components/lp/Report";
import Demo from "../components/lp/Demo";
import CTA from "../components/lp/CTA";
import Testimonials from "../components/lp/Testimonials";
import ContactUs from "../components/lp/ContactUs/ContactUs";
import Header from "../components/lp/Header";
import Footer from "../components/lp/Footer";
import Operational from "../components/lp/Operational";
import BlankLayout from "@core/layouts/BlankLayout";
import { Jost } from "next/font/google";
import { ThemeProvider } from "@mui/material/styles";
import theme from "@core/theme/lp-theme";

const jost = Jost({ subsets: ["latin"] });
/**
 *  Set Home URL based on User Roles
 */
//login change
const Home = () => {
  return (
    <div className={`${jost.className}`}>
      <ThemeProvider theme={theme}>
        <Header />
        <Hero />
        <SeoBanner />
        <Team />
        <Partners />
        <Report />
        <Operational />
        <Demo />
        <CTA />
        <Testimonials />
        <ContactUs />
        <Footer />
      </ThemeProvider>
    </div>
  );
};

Home.getLayout = page => <BlankLayout>{page}</BlankLayout>;
Home.guestGuard = true;

export default Home;
