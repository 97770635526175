import { Box, Grid, useMediaQuery } from "@mui/material";
import Image from "next/image";
import google from "@public/images/lp/icons/google.svg";
import yelp from "@public/images/lp/icons/yelp.svg";
import microsoft from "@public/images/lp/icons/microsoft.svg";
import linkedin from "@public/images/lp/icons/linkedin.svg";
import meta from "@public/images/lp/icons/meta.svg";

const social = [google, yelp, microsoft, linkedin, meta];

const Partners = () => {
  const isMobile = useMediaQuery("(max-width:720px)");

  return (
    <Box>
      <Box
        container
        sx={{
          margin: "0 auto",
          background: "#f2f3fa",
          m: { lg: "100px 0 160px 0", xs: "60px 0px" }
        }}
      >
        <Grid
          spacing={10}
          sx={{
            display: "flex",
            flexWrap: "noWrap",
            justifyContent: "space-between",
            alignItems: "center",
            overflow: "auto",
            p: { lg: "45px 65px", xs: "24px" },
            "&::-webkit-scrollbar": {
              display: "none"
            }
          }}
        >
          {social.map((item, index) => (
            <Grid
              key={index}
              sx={{
                maxWidth: isMobile ? "120px" : "200px",
                marginLeft: index > 0 ? (isMobile ? "20px" : "50px") : 0
              }}
            >
              <Image
                src={item}
                alt=''
                layout='responsive'
                width={isMobile ? 75 : 100}
                height={isMobile ? 75 : 100}
                sx={{
                  width: "100%",
                  height: "auto",
                  minWidth: isMobile ? "75px" : "100px"
                }}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default Partners;
