export const testimonials = [
  {
    author: "John",
    photo: "../../../images/lp/testimonials/John.png",
    position: "Head of Marketing at Glossy",
    testimonial:
      "I have been using this Dashboard for my social media marketing efforts, and I must say, it has been a game-changer for me. The ability to track and analyze my social media insights in one place has significantly impacted my strategy.   ",
    rating: 5
  },
  {
    author: "Sarah",
    photo: "../../../images/lp/testimonials/Sarah.png",
    position: "Head of Marketing at Glossy",
    testimonial:
      "My daily go-to dashboard. I have tried several business dashboards, but MyVigorant Dashboard stands out. It offers a user-friendly interface that makes it easy for non-technical users like me to navigate and analyze data.",
    rating: 5
  },
  {
    author: "Michael",
    photo: "../../../images/lp/testimonials/Michael.png",
    position: "Head of Marketing at Glossy",
    testimonial:
      "Thumbs up for their reporting feature. It provides comprehensive data that allows me to make informed decisions and optimize my marketing strategies. The support team is also amazing, always ready to assist and provide valuable guidance. This tool has truly made my life easier!",
    rating: 5
  },
  {
    author: "David",
    photo: "../../../images/lp/testimonials/David.png",
    position: "Head of Marketing at Glossy",
    testimonial:
      "My.Vigorant Dashboard has been a game-changer for our marketing team. The ability to automate various tasks has freed up our time. We couldn't be happier with this tool! ",
    rating: 5
  }
];

export const settings = {
  dots: true,
  arrows: false,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  centerPadding: "0px",
  responsive: [
    {
      breakpoint: 1310, // Medium and larger screens
      settings: {
        slidesToShow: 3,
        initialSlide: 3
      }
    },
    {
      breakpoint: 1200, // Tablets and larger screens
      settings: {
        dots: false,
        slidesToShow: 2,
        initialSlide: 2
      }
    },
    {
      breakpoint: 520, // Phones and larger screens
      settings: {
        dots: false,
        slidesToShow: 1,
        initialSlide: 1
      }
    }
  ]
};
