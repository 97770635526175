import { Box, Button, Grid, Typography } from "@mui/material";
import Link from "next/link";
import Image from "next/image";
import { Link as ScrollLink } from "react-scroll";

const Demo = () => {
  return (
    <Box
      sx={{
        background: "linear-gradient(90.73deg, #B111CB -40.16%, #1E54F5 144.89%)",
        padding: { lg: "100px 65px", xs: "60px 24px" }
      }}
      id='demo'
    >
      <Grid container spacing={6}>
        <Grid item lg={6} xs={12}>
          <Typography
            sx={{
              lineHeight: "35px",
              color: "text.white",
              fontSize: { lg: "42px", sm: "28px", xs: "18px" },
              fontWeight: 700,
              letterSpacing: "0.03em"
            }}
          >
            Request A Demo
          </Typography>

          <Typography
            sx={{
              fontSize: { sm: "18px", xs: "14px" },
              fontWeight: 500,
              lineHeight: { sm: "43px", xs: "24px" },
              letterSpacing: "0.03em",
              color: "text.whiteLight",
              mt: "20px",
              mb: "20px",
              maxWidth: "693px"
            }}
          >
            Stop struggling with scattered data and complex reports. Our intuitive dashboard provides a centralized
            location to view all your key metrics.
          </Typography>

          <Button
            variant='contained'
            color='secondary'
            sx={{
              whiteSpace: "nowrap",
              textTransform: "none",
              borderRadius: "12px",
              height: { sm: "70px", xs: "46px" },
              minWidth: { sm: "236px", xs: "50%" },
              fontSize: { md: "24px", sm: "18px" },
              letterSpacing: "0.72px",
              fontWeight: 700,
              marginBottom: "50px",
              color: "#8326D8"
            }}
          >
            <ScrollLink to='contact' smooth={true} duration={1000} offset={-100}>
              Request a Demo
            </ScrollLink>
          </Button>
        </Grid>

        <Grid item lg={6} xs={12}>
          <Box sx={{ display: "flex" }}>
            <Image
              src='/images/lp/OverviewSEO.png'
              alt='Overview'
              layout='responsive'
              width={812}
              height={468}
              sx={{ maxWidth: "100%", height: "auto" }}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Demo;
