import { Box, Button, Grid, Typography, useMediaQuery } from "@mui/material";
import Link from "next/link";
import Image from "next/image";
import Reporting from "@public/images/lp/Reporting2.png";
import { Link as ScrollLink } from "react-scroll";

const Report = () => {
  const isTablet = useMediaQuery("(max-width:900px)");

  return (
    <Box sx={{ backgroundColor: "background.primary" }}>
      <Box
        sx={{
          maxWidth: "1920px",
          margin: "0 auto",
          padding: {
            lg: "100px 50px",
            md: "80px 60px",
            xs: "60px 24px"
          },
          textAlign: { md: "left", xs: "center" }
        }}
      >
        <Grid container sx={{ justifyContent: "space-between" }}>
          <Grid item lg={5} xs={12} sx={{ pr: { lg: "0", md: "60px", xs: "24" } }}>
            <Typography
              sx={{
                color: "text.white",
                fontSize: { md: "42px", sm: "24px", xs: "18px" },
                fontWeight: 700,
                letterSpacing: { md: "1.26px", xs: "0.54" },
                maxWidth: { md: "480px", sx: "100%" }
              }}
            >
              Reporting
            </Typography>

            <Typography
              sx={{
                color: "text.white",
                fontSize: { md: "22px", sm: "20px", xs: "18px" },
                fontWeight: 700,
                lineHeight: "43px",
                letterSpacing: "3%"
              }}
            >
              Unlock the power of data with insightful reports!
            </Typography>

            <Typography
              sx={{
                color: "text.whiteDark",
                mt: "20px",
                mb: "10px",
                fontSize: { sm: "18px", xs: "14px" },
                fontWeight: 500,
                lineHeight: { sm: "43px", xs: "24px" },
                letterSpacing: { sm: "0.54px", xs: "0.42px" }
              }}
            >
              Streamline your reporting process by customizing and filtering reports to reveal hidden trends and
              cause-and-effect relationships with ease.
            </Typography>

            <Typography
              sx={{
                color: "text.whiteDark",
                mt: "20px",
                mb: "45px",
                fontSize: { sm: "18px", xs: "14px" },
                fontWeight: 500,
                lineHeight: { sm: "43px", xs: "24px" },
                letterSpacing: { sm: "0.54px", xs: "0.42px" }
              }}
            >
              Our intuitive interface enables you to highlight key insights and transform data into actionable
              decisions, driving your business forward.
            </Typography>

            <Button
              href='/login'
              component={Link}
              variant='contained'
              color='secondary'
              sx={{
                whiteSpace: "nowrap",
                textTransform: "none",
                borderRadius: "12px",
                height: { sm: "70px", xs: "46px" },
                minWidth: { sm: "236px", xs: "50%" },
                fontSize: { md: "24px", sm: "18px" },
                letterSpacing: "0.72px",
                fontWeight: 700,
                marginBottom: "50px"
              }}
            >
              Get Started
            </Button>
          </Grid>

          {!isTablet && (
            <Grid item lg={6} xs={12}>
              <Image
                src={Reporting}
                alt='Reporting'
                layout='responsive'
                width={877}
                height={646}
                sx={{ maxWidth: "100%", height: "auto" }}
              />
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  );
};

export default Report;
