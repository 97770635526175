import { Typography } from "@mui/material";

export const CustomTitle = ({ text, colorText, sx }) => {
  return (
    <Typography sx={sx}>
      {text}{" "}
      <span
        style={{
          background: "linear-gradient(90deg, #2E6ADB -0.07%, #AE6FE9 90.44%)",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent"
        }}
      >
        {colorText}
      </span>
    </Typography>
  );
};

export default CustomTitle;
