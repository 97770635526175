import { Box, Button, FormControl, FormHelperText, Grid, OutlinedInput, Typography } from "@mui/material";
import CustomTitle from "@components/lp/common/CustomTitle";
import Card from "@mui/material/Card";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";

import {
  COMPANY_NAME_LENGTH,
  INVALID_PHONE_NUMBER,
  INVALID_URL,
  LETTERS_ONLY,
  MAX_LENGTH,
  REQUIRED_FIELD
} from "@constants/validation";
import { PHONE_REGEX, TEXT_REGEX, URL_REGEX } from "@constants/regex";
import { sendContactForm } from "@store/apps/auth";
import { useDispatch } from "react-redux";

const ContactUs = () => {
  const dispatch = useDispatch();

  const initialValues = {
    fullName: "",
    phone: "",
    company: "",
    url: ""
  };

  const [values, setValues] = useState(initialValues);

  const handleChange = (field, value) => {
    setValues(prevValues => ({
      ...prevValues,
      [field]: value
    }));
  };

  const schema = yup.object().shape({
    fullName: yup.string().trim().required(REQUIRED_FIELD).matches(TEXT_REGEX, LETTERS_ONLY).max(100, MAX_LENGTH),
    phone: yup.string().trim().required(REQUIRED_FIELD).matches(PHONE_REGEX, INVALID_PHONE_NUMBER).max(50, MAX_LENGTH),
    company: values.company && yup.string().trim().min(3, COMPANY_NAME_LENGTH),
    url: values.url && yup.string().matches(URL_REGEX, INVALID_URL)
  });

  const {
    reset,
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues: initialValues,
    mode: "onChange",
    resolver: yupResolver(schema)
  });

  const onSubmit = data => {
    dispatch(sendContactForm(data));
    setValues(initialValues);
    reset();
  };

  const isDisable = Object.keys(errors).length === 0 && values.phone !== "" && values.fullName !== "";

  return (
    <Box sx={{ backgroundColor: "background.white" }} id='contact'>
      <Grid
        container
        sx={{
          padding: { lg: "150px 80px", md: "100px 40px ", sm: "80px 24px" },
          justifyContent: "space-between",
          m: 0,
          maxWidth: "1920px",
          margin: "0 auto"
        }}
      >
        <Grid item xl={6} lg={6} sx={{ padding: "25px" }}>
          <CustomTitle
            sx={{
              fontSize: { md: "64px", sm: "24px", xs: "20px" },
              fontWeight: 450,
              lineHeight: { md: "100px", xs: "31px" },
              mt: { md: "39px", xs: "24px" },
              maxWidth: "516px"
            }}
            colorText="Let's make things happen!"
          />

          <Typography
            sx={{
              color: "text.greyLight",
              fontSize: "18px",
              fontWeight: 500,
              lineHeight: { md: "32px", xs: "24px" },
              letterSpacing: { md: "0.54px", xs: "0.36px" },
              mb: "40px",
              mt: "10px",
              maxWidth: "516px"
            }}
          >
            Connect with our experts at Vigorant to learn how to engage more clients. Our team is dedicated to turning
            your business success dreams into reality!
          </Typography>
        </Grid>

        <Grid item xl={6} lg={6} sx={{ margin: { xs: "0 auto", md: "0" } }}>
          <Card
            sx={{
              boxShadow: "0px 0px 45.5px 0px rgba(0, 0, 0, 0.05)",
              padding: "40px 24px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              ml: { lg: "60px", xs: 0 }
            }}
          >
            <Typography
              sx={{
                color: "text.primary",
                fontSize: { md: "34px", xs: "20px" },
                fontWeight: 700,
                lineHeight: "28.6px",
                mb: "38px"
              }}
            >
              Contact Us
            </Typography>

            <form
              onSubmit={handleSubmit(onSubmit)}
              method='POST'
              style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
            >
              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  <FormControl sx={{ width: "100%" }}>
                    <Controller
                      name='fullName'
                      control={control}
                      render={({ field: { value, onChange, onBlur } }) => (
                        <OutlinedInput
                          sx={{
                            fontSize: { md: "16px", xs: "12px" },
                            color: "text.greyDark",
                            backgroundColor: "rgba(13, 127, 209, 0.16)",
                            borderRadius: "10px",
                            "& .MuiOutlinedInput-notchedOutline": { border: "none" }
                          }}
                          id='name'
                          value={values?.fullName}
                          onBlur={onBlur}
                          onChange={event => {
                            onChange(event);
                            handleChange("fullName", event.target.value);
                          }}
                          placeholder='Full Name*'
                        />
                      )}
                    />
                    <FormHelperText sx={{ color: "red" }}>
                      {errors?.fullName && errors?.fullName?.message}
                    </FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item md={6} xs={12}>
                  <FormControl sx={{ width: "100%" }}>
                    <Controller
                      name='phone'
                      control={control}
                      render={({ field: { value, onChange, onBlur } }) => (
                        <OutlinedInput
                          sx={{
                            fontSize: { md: "16px", xs: "12px" },
                            color: "text.greyDark",
                            backgroundColor: "rgba(13, 127, 209, 0.16)",
                            borderRadius: "10px",
                            "& .MuiOutlinedInput-notchedOutline": { border: "none" }
                          }}
                          value={values?.phone}
                          id='number'
                          onBlur={onBlur}
                          onChange={event => {
                            onChange(event);
                            handleChange("phone", event.target.value);
                          }}
                          error={Boolean(errors.company)}
                          placeholder='Phone Number*'
                        />
                      )}
                    />
                    <FormHelperText sx={{ color: "red" }}>{errors?.phone && errors?.phone?.message}</FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item md={6} xs={12}>
                  <FormControl sx={{ width: "100%" }}>
                    <Controller
                      name='company'
                      control={control}
                      render={({ field: { value, onChange, onBlur } }) => (
                        <OutlinedInput
                          sx={{
                            fontSize: { md: "16px", xs: "12px" },
                            color: "text.greyDark",
                            backgroundColor: "rgba(13, 127, 209, 0.16)",
                            borderRadius: "10px",
                            "& .MuiOutlinedInput-notchedOutline": { border: "none" }
                          }}
                          value={values?.company}
                          id='company'
                          onBlur={onBlur}
                          onChange={event => {
                            onChange(event);
                            handleChange("company", event.target.value);
                          }}
                          error={Boolean(errors.company)}
                          placeholder='Company Name (Optional)'
                        />
                      )}
                    />
                    <FormHelperText sx={{ color: "red" }}>{errors?.company && errors?.company?.message}</FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item md={6} xs={12}>
                  <FormControl sx={{ width: "100%" }}>
                    <Controller
                      name='url'
                      control={control}
                      render={({ field: { value, onChange, onBlur } }) => (
                        <OutlinedInput
                          value={values?.url}
                          sx={{
                            fontSize: { md: "16px", xs: "12px" },
                            color: "text.greyDark",
                            backgroundColor: "rgba(13, 127, 209, 0.16)",
                            borderRadius: "10px",
                            "& .MuiOutlinedInput-notchedOutline": { border: "none" }
                          }}
                          id='website'
                          onBlur={onBlur}
                          onChange={event => {
                            onChange(event);
                            handleChange("url", event.target.value);
                          }}
                          error={Boolean(errors.url)}
                          placeholder='Website URL (Optional)'
                        />
                      )}
                    />
                    <FormHelperText sx={{ color: "red" }}>{errors?.url && errors?.url?.message}</FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>

              <Button
                type='submit'
                variant='contained'
                disabled={!isDisable}
                sx={{
                  mt: "28px",
                  backgroundColor: "text.primary",
                  whiteSpace: "nowrap",
                  boxShadow: "0px 10px 50px 0px rgba(50, 73, 179, 0.08)",
                  textTransform: "none",
                  borderRadius: "18px",
                  height: { sm: "70px", xs: "38px" },
                  maxWidth: { sm: "317px", xs: "100%" },
                  width: "100%",
                  fontSize: { md: "21px", sm: "18px", xs: "14px" },
                  fontWeight: 700
                }}
              >
                Get in touch
              </Button>
            </form>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};
export default ContactUs;
