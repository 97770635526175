const MenuIcon = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M21 10H3' stroke='black' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M21 6H3' stroke='black' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M21 14H3' stroke='black' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M21 18H3' stroke='black' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  );
};

export default MenuIcon;
