const PhoneIcon = ({ color = "#1B69AF", width = "48", height = "48" }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect x='0.5' y='0.5' width='47' height='47' rx='23.5' stroke={color} />
      <g clipPath='url(#clip0_136_1500)'>
        <path
          d='M29.6073 33.3197C28.3018 33.2148 27.0434 32.7844 25.9471 32.0677C23.825 30.9342 21.8871 29.4855 20.1994 27.7709C18.453 26.1075 16.9947 24.1659 15.8837 22.0252C14.3518 19.2393 14.6126 17.7784 14.9044 17.1539C15.2414 16.4716 15.7713 15.9032 16.4282 15.5191C16.805 15.2721 17.2037 15.0604 17.6193 14.8866L17.7343 14.8366C17.8717 14.7589 18.0242 14.7113 18.1815 14.697C18.3388 14.6827 18.4973 14.702 18.6466 14.7537C18.9784 14.9134 19.2737 15.14 19.5139 15.4192C20.3789 16.3897 21.1086 17.4727 21.6833 18.6388C21.9196 19.0422 22.0649 19.4923 22.109 19.9578C22.0749 20.4192 21.8882 20.8562 21.5784 21.1998C21.5234 21.2738 21.4695 21.3457 21.4175 21.4147C21.1007 21.8304 21.0318 21.9503 21.0778 22.1651C21.501 23.2442 22.1567 24.2169 22.9983 25.014C23.7833 25.8435 24.746 26.4844 25.8142 26.8886C26.038 26.9365 26.1609 26.8636 26.5896 26.5368C26.6516 26.4899 26.7145 26.4369 26.7805 26.3929C27.1245 26.0691 27.5678 25.8709 28.0385 25.8304C28.5106 25.8788 28.9646 26.038 29.3635 26.295C30.5163 26.8797 31.5935 27.6028 32.5711 28.4484C32.8508 28.6878 33.0781 28.9824 33.2386 29.3137C33.2902 29.4635 33.3094 29.6225 33.2951 29.7803C33.2809 29.938 33.2333 30.091 33.1557 30.2291C33.1407 30.264 33.1237 30.301 33.1057 30.343C32.9307 30.7577 32.718 31.1554 32.4702 31.5311C32.0848 32.1874 31.5154 32.7161 30.8324 33.0519C30.4484 33.2335 30.0281 33.3254 29.6033 33.3207L29.6073 33.3197Z'
          fill={color}
        />
      </g>
      <defs>
        <clipPath id='clip0_136_1500'>
          <rect width='18.63' height='18.63' fill='white' transform='translate(14.6797 14.6899)' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PhoneIcon;
