import { Box, Button, Grid, List, ListItem, ListItemText, Typography } from "@mui/material";
import Image from "next/image";
import DailyDataEntry from "@public/images/lp/DailyDataEntry.png";
import Link from "next/link";

const Operational = () => {
  return (
    <Box sx={{ backgroundColor: "#fff" }}>
      <Box
        sx={{
          maxWidth: "1920px",
          margin: "0 auto",
          padding: { lg: "100px 65px", xs: "60px 24px" }
        }}
      >
        <Grid spacing={10} container>
          <Grid
            item
            lg={6}
            xs={12}
            sx={{
              pr: { lg: "0", md: "60px", xs: "24" },
              borderRadius: "10px"
            }}
          >
            <Box sx={{ boxShadow: "0px 4px 4px 0px #00000040", borderRadius: "10px" }}>
              <Image
                src={DailyDataEntry}
                alt='Reporting'
                layout='responsive'
                width={1000}
                height={722}
                sx={{
                  maxWidth: "100%",
                  height: "auto",
                  borderRadius: "10px"
                }}
              />
            </Box>
          </Grid>

          <Grid item lg={6} xs={12} sx={{ pr: { lg: "0", md: "60px", xs: "24" } }}>
            <Typography
              sx={{
                fontSize: { lg: "42px", sm: "28px", xs: "18px" },
                fontWeight: 700,
                lineHeight: "53.13px",
                letterSpacing: { md: "1.26px", xs: "0.54px" },
                color: "background.primary"
              }}
            >
              Operational Analysis
            </Typography>

            <Typography
              sx={{
                fontSize: { lg: "24px", sm: "20px", xs: "18px" },
                fontWeight: 500,
                lineHeight: "40px",
                color: "#303030",
                letterSpacing: "3%"
              }}
            >
              Track your business processes daily!
            </Typography>

            <Typography
              sx={{
                fontSize: { lg: "18px", sm: "16px", xs: "14px" },
                fontWeight: 500,
                lineHeight: "40px",
                color: "text.greyLight",
                letterSpacing: "3%"
              }}
            >
              Collect real-time data on business operations and gain insights through detailed historical diagrams
              showcasing process efficiency. Our meticulously crafted visual graphs and information empower business
              owners to make confident, data-driven decisions. This dashboard encompasses the following areas:
            </Typography>

            <List
              sx={{
                pl: 2,
                listStyleType: "disc",
                "& .MuiListItem-root": {
                  display: "list-item",
                  fontWeight: 500,
                  lineHeight: "40px",
                  color: "text.greyLight",
                  letterSpacing: "3%"
                }
              }}
            >
              <ListItem disablePadding>
                <ListItemText primary='Daily Production: Compare daily production to your objectives and projected output.' />
              </ListItem>
              <ListItem disablePadding>
                <ListItemText primary='Daily Collection: Compare collections to production to monitor changes in accounts receivable.' />
              </ListItem>
              <ListItem disablePadding>
                <ListItemText primary='New Patient Analysis: Obtain detailed statistics on the channels bringing in new clients or patients.' />
              </ListItem>
              <ListItem disablePadding>
                <ListItemText primary='Appointment Schedule Analysis: Evaluate the strength of schedule coordination within your business.' />
              </ListItem>
            </List>
            <Button
              href='https://vigorant.com/about'
              component={Link}
              variant='contained'
              color='primary'
              sx={{
                mt: "30px",
                backgroundColor: "background.primary",
                whiteSpace: "nowrap",
                textTransform: "none",
                borderRadius: "12px",
                lineHeight: "30.36px",
                height: { sm: "70px", xs: "46px" },
                minWidth: { sm: "342px", xs: "50%" },
                fontSize: { md: "24px", sm: "18px" },
                letterSpacing: "3%",
                fontWeight: 700,
                marginBottom: "50px"
              }}
            >
              See About Us
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Operational;
