import { Box, Typography } from "@mui/material";
import CustomTitle from "@components//lp/common/CustomTitle";
import { testimonials, settings } from "@constants/lp/testimonials";
import TestimonialCard from "@components/lp/Testimonials/TestimonialCard";
import Slider from "react-slick";

const Testimonials = () => {
  return (
    <Box sx={{ backgroundColor: "background.grayWarm", overflow: "hidden" }} id='testimonials'>
      <Box
        sx={{
          padding: { lg: "82px 0 82px 61px", md: "62px 0 62px 40px", xs: "40px 0 40px 24px" },
          maxWidth: "1920px",
          margin: "0 auto"
        }}
      >
        <Typography
          sx={{
            color: "text.primary",
            fontSize: { md: "16px", sm: "12px", xs: "8px" },
            fontWeight: 700,
            lineHeight: "16px",
            textTransform: "uppercase"
          }}
        >
          Hear from our <span style={{ color: "#132444" }}>satisfied clients.</span>
        </Typography>

        <CustomTitle
          sx={{
            fontSize: { md: "50px", sm: "28px", xs: "18px" },
            fontWeight: 700
          }}
          colorText='Hear Directly From Our Happy Clients '
        />

        <Box sx={{ mt: { lg: "65px", sm: "40px" }, mr: "-200px", height: "100%" }}>
          <Slider {...settings} className='testimonials-slider'>
            {testimonials.map((item, index) => (
              <Box key={index} sx={{ p: "20px", height: "430px" }}>
                <TestimonialCard
                  author={item.author}
                  photo={item.photo}
                  position={item.position}
                  rating={item.rating}
                  testimonial={item.testimonial}
                />
              </Box>
            ))}
          </Slider>
        </Box>
      </Box>
    </Box>
  );
};

export default Testimonials;
