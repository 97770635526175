const EmailIcon = ({ color = "#1B69AF", width = "48", height = "48" }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect x='0.5' y='0.5' width='47' height='47' rx='23.5' stroke={color} />
      <g clipPath='url(#clip0_136_1506)'>
        <path
          d='M33.9295 17.8829C33.9295 17.6143 33.8228 17.3567 33.6329 17.1668C33.443 16.9768 33.1854 16.8701 32.9168 16.8701H15.0821C14.8135 16.8701 14.5559 16.9768 14.366 17.1668C14.176 17.3567 14.0693 17.6143 14.0693 17.8829V30.1273C14.0693 30.3959 14.176 30.6535 14.366 30.8435C14.5559 31.0334 14.8135 31.1401 15.0821 31.1401H32.9168C33.1854 31.1401 33.443 31.0334 33.6329 30.8435C33.8228 30.6535 33.9295 30.3959 33.9295 30.1273V17.8829ZM31.9031 19.9465C31.9031 20.0938 31.8712 20.2395 31.8095 20.3733C31.7477 20.5071 31.6577 20.6259 31.5455 20.7215L27.6604 24.0296L31.5475 27.3406C31.7046 27.4742 31.8169 27.6527 31.8692 27.8521C31.9216 28.0515 31.9114 28.2622 31.8402 28.4556C31.769 28.6491 31.64 28.816 31.4709 28.9339C31.3017 29.0517 31.1005 29.1148 30.8943 29.1145H30.8853C30.6394 29.1146 30.4015 29.0271 30.2142 28.8678L26.0953 25.361L24.6561 26.5875C24.4728 26.7434 24.2401 26.829 23.9994 26.829C23.7588 26.829 23.5261 26.7434 23.3428 26.5875L21.9006 25.36L17.7887 28.8618C17.5969 29.025 17.3533 29.1146 17.1016 29.1145C16.8954 29.1148 16.6942 29.0517 16.525 28.9339C16.3558 28.816 16.2269 28.6491 16.1557 28.4556C16.0845 28.2622 16.0743 28.0515 16.1267 27.8521C16.179 27.6527 16.2913 27.4742 16.4484 27.3406L20.3375 24.0296L16.5073 20.7675C16.3783 20.6576 16.2747 20.521 16.2037 20.3672C16.1326 20.2134 16.0958 20.046 16.0958 19.8765C16.0958 19.6894 16.1494 19.5062 16.2502 19.3485C16.351 19.1909 16.4948 19.0654 16.6647 18.9869C16.8346 18.9085 17.0233 18.8803 17.2087 18.9057C17.3941 18.9311 17.5683 19.0091 17.7108 19.1304L23.6793 24.2134C23.7691 24.2884 23.8824 24.3295 23.9994 24.3295C24.1164 24.3295 24.2297 24.2884 24.3195 24.2134L30.2881 19.1304C30.4306 19.0091 30.6048 18.9311 30.7902 18.9057C30.9755 18.8803 31.1643 18.9085 31.3342 18.9869C31.5041 19.0654 31.6479 19.1909 31.7487 19.3485C31.8495 19.5062 31.9031 19.6894 31.9031 19.8765V19.9465Z'
          fill={color}
        />
      </g>
      <defs>
        <clipPath id='clip0_136_1506'>
          <rect width='19.88' height='14.27' fill='white' transform='translate(14.0596 16.8701)' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EmailIcon;
