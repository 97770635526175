export const footer_navigation = [
  {
    name: "Company",
    list: [
      {
        title: "About",
        link: "https://vigorant.com/about"
      },
      {
        title: "Contact",
        link: "https://vigorant.com/contact"
      },
      {
        title: "Career",
        link: "https://vigorant.com/careers"
      },
      {
        title: "How it works",
        link: "https://vigorant.com/how-we-works"
      },
      {
        title: "Privacy",
        link: "https://my.vigorant.com/legal/privacy-policy/"
      },
      {
        title: "Disclosure",
        link: "https://dev.my.vigorant.com/legal/disclosure"
      }
    ]
  },
  {
    name: "Services",
    list: [
      {
        title: "Web Design",
        link: "https://vigorant.com/services/web-design"
      },
      {
        title: "SEO Search Engine Optimization",
        link: "https://vigorant.com/services/search-engine-optimization"
      },
      {
        title: "PPC Marketing",
        link: "https://vigorant.com/services/ppc-marketing"
      },
      {
        title: "Social Media Marketing",
        link: "https://vigorant.com/services/social-media-marketing"
      },
      {
        title: "Email Marketing",
        link: "https://vigorant.com/services/email-marketing"
      },
      {
        title: "Terms of Service",
        link: "https://my.vigorant.com/legal/terms-of-service/"
      }
    ]
  },
  {
    name: "Industries",
    list: [
      {
        title: "Dentist",
        link: "https://vigorant.com/digital-marketing-for-denstists"
      },
      {
        title: "Doctors",
        link: "https://vigorant.com/doctors"
      },
      {
        title: "Law Firms",
        link: "https://vigorant.com/law-firms"
      },
      {
        title: "Startups",
        link: "https://vigorant.com/startups"
      },
      {
        title: "Chiropractor",
        link: "https://vigorant.com/chiropractor"
      },
      {
        title: "Real Estate",
        link: "https://vigorant.com/real-estate"
      }
    ]
  },
  {
    name: "Resources",
    list: [
      {
        title: "Blogs",
        link: "https://vigorant.com/blog"
      },
      {
        title: "Case Studies",
        link: "https://vigorant.com/case-studies"
      },
      {
        title: "Portfolio",
        link: "https://vigorant.com/portfolio"
      }
    ]
  }
];
