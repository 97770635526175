import { createTheme } from "@mui/material";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1310
    }
  },
  palette: {
    background: {
      gray: "rgba(27, 105, 175, 0.13)",
      grayLight: "rgba(46, 106, 219, 0.06)",
      grayWarm: "#E5E5E5",
      primary: "#3067DB",
      white: "#FAFAFA"
    },
    primary: {
      main: "#2E6ADB",
      contrastText: "#fff",
      textTransform: "capitalize"
    },
    secondary: {
      main: "#FFF",
      dark: "rgba(250, 250, 250, 0.23)",
      contrastText: "#2E6ADB"
    },
    text: {
      violet: "#5D5DFF",
      primary: "#1B69AF",
      primaryLight: "#4774DF",
      primaryDark: "#132444",
      greyLight: "#797979",
      greyMain: "#6F6C90",
      greyDark: "#202020",
      white: "#FAFAFA",
      whiteDark: "#F5F5F5",
      whiteLight: "#FFF"
    }
  }
});

export default theme;
