import { Box, Grid, Typography } from "@mui/material";
import Image from "next/image";
import OBJECTS from "@public/images/lp/OBJECTS.png";

const CTA = () => {
  return (
    <Box
      sx={{
        padding: {
          maxWidth: "1726px",
          margin: "0 auto",
          lg: "132px 84px",
          md: "80px 60px",
          xs: "40px 24px"
        }
      }}
    >
      <Grid
        container
        sx={{
          backgroundColor: "background.primary",
          borderRadius: "92px",
          padding: { lg: "70px", md: "60px", xs: "60px 40px" },
          justifyContent: "space-between"
        }}
      >
        <Grid
          item
          xl={5}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          sx={{ order: { xl: 1, xs: 2 }, marginTop: { xl: 0, xs: "40px" } }}
        >
          <Image
            src={OBJECTS}
            alt='Social Media'
            layout='responsive'
            width={678}
            height={442}
            sx={{ maxWidth: "100%", height: "auto" }}
          />
        </Grid>

        <Grid item xl={6} lg={12} md={12} sm={12} xs={12} sx={{ ml: { lg: "80px", md: 0, sm: 0, xs: 0 }, order: 1 }}>
          <Typography
            sx={{
              color: "text.white",
              fontSize: { lg: "42px", sm: "28px", xs: "18px" },
              fontWeight: 700
            }}
          >
            Let’s Help You Focus on Your Core Competency
          </Typography>

          <Typography
            sx={{
              color: "text.whiteDark",
              mt: "25px",
              mb: "19px",
              fontWeight: 500,
              lineHeight: { md: "43px", xs: "24px" },
              letterSpacing: { md: "0.54px", sm: "0.42px" },
              fontSize: { md: "18px", sm: "14px" }
            }}
          >
            We free you from the burdens of ancillary tasks like reporting, operations, and marketing, so you can
            prioritize your core business and expertise. By managing reporting processes and monitoring operational
            workflows, we take care of time-consuming responsibilities, allowing you to concentrate on innovation and
            growth.
          </Typography>

          <Typography
            sx={{
              color: "text.whiteDark",
              fontWeight: 500,
              lineHeight: { md: "43px", xs: "24px" },
              letterSpacing: { md: "0.54px", sm: "0.42px" },
              fontSize: { md: "18px", sm: "14px" }
            }}
          >
            With our support, you can focus on your core strengths, foster creativity, and seize new opportunities,
            knowing that essential tasks are expertly handled. This ensures your business's success and longevity.
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CTA;
