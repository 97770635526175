export const seo = [
  {
    title: "Digital Marketing Dashboard",
    subtitle: "Maximize the return on your marketing budget with our comprehensive digital marketing dashboard!",
    text:
      "This guide covers every analytical aspect crucial for businesses" +
      " to monitor, manage, and enhance their market presence. By employing a data-driven approach, " +
      "it allows business owners to evaluate the effectiveness and efficiency of their marketing efforts across various domains.",
    image: "/images/lp/OverviewSEO.png"
  },
  {
    title: "Search Engine Optimization",
    subtitle: "",
    text: "Optimization This analytical dashboard offers you enhanced search engine rankings and organic visibility by monitoring keywords and traffic.",
    image: "/images/lp/SEO.png"
  },
  {
    title: "Social Media Marketing",
    subtitle: "",
    text: "Enhance your targeting and content strategy to boost engagement and conversion rates across social platforms.",
    image: "/images/lp/SocialMedia.png"
  },
  {
    title: "Reputation Management",
    subtitle: "",
    text: "My.Vigorant monitors sentiment and tracks online mentions to support proactive reputation management. By providing insights into public perception, it helps businesses effectively manage and enhance their reputation.",
    image: "/images/lp/CustomerReputation1.png"
  }
];
